// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';
import apolloProvider from './apollo';

// Animate on scroll
import AOS from 'aos';

// Components
import Accordion from './components/Accordion.vue';
import Clinics from './components/Clinics.vue';
import Gallery from './components/Gallery.vue';
import MainMenu from './components/MainMenu.vue';
import Modal from './components/Modal.vue';
import Publications from './components/Publications.vue';
import Rot13Email from './components/Rot13Email.vue';
import Sensitive from './components/Sensitive.vue';
import Lightbox from './components/Lightbox.vue';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper';

const app = createApp({
    delimiters: [ '${', '}' ],

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            swiperModules: [ Autoplay, Pagination ],
            isMenuOpen: false,
        };
    },

    mounted() {
        setTimeout(() => {
            AOS.init({
                disable: window.outerWidth < 768,
                duration: 400,
            });
        }, 300);
    },

    methods: {
        onToggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
});

app.component('Accordion', Accordion);
app.component('Clinics', Clinics);
app.component('CollapseTransition', CollapseTransition);
app.component('Gallery', Gallery);
app.component('MainMenu', MainMenu);
app.component('Modal', Modal);
app.component('Publications', Publications);
app.component('Rot13Email', Rot13Email);
app.component('Sensitive', Sensitive);
app.component('Lightbox', Lightbox);

app.use(apolloProvider);

app.mount('#app');
